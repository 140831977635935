*, *::before, *::after {
  outline: none;
}
a {
  color: var(--p-interactive);
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}
.login-page {
  .Polaris-Frame--hasNav .Polaris-Frame__Main {
    padding-left: 0;
  }
  .page-404 {
    display: none;
  }
}
.Polaris-Navigation__Navigation--newDesignLanguage .Polaris-Navigation__Item {
  font-weight: 500;
}
.Polaris-TopBar__TopBar--newDesignLanguage {
  box-shadow: var(--p-top-bar-shadow);
}
.Polaris-Navigation__Navigation--newDesignLanguage .Polaris-Navigation__Section {
  padding-top: 1.6rem;
}

.Polaris-Navigation__ListItem {
  align-items: center;
  .secondaryAction {
    padding-right: 1rem;
    display: inline-flex;
  }
}
.ant-select-item-option-selected {
  &:not(.ant-select-item-option-disabled) {
    background: #f5f6f7;
  }
  .anticon-check {
    color: green;
  }
}
.ant-select-selection-search-input {
  font-size: 1.4rem;
}
.ant-btn {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: 3.6rem;
  min-width: 3.6rem;
  margin: 0;
  padding: 0.7rem 1.6rem;
  background: linear-gradient(to bottom, white, #f9fafb);
  border: 0.1rem solid var(--p-border, #c4cdd5);
  box-shadow: 0 1px 0 0 rgba(22, 29, 37, 0.05);
  border-radius: 3px;
  line-height: 1;
  color: #212b36;
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  text-decoration: none;
  transition-property: background, border, box-shadow;
  transition-duration: var(--p-override-none, 200ms);
  transition-timing-function: var(--p-override-none, cubic-bezier(0.64, 0, 0.35, 1));
  -webkit-tap-highlight-color: transparent;
}
.ant-btn-primary {
  --p-button-color: var(--p-action-primary);
  --p-button-text: var(--p-text-on-primary);
  --p-button-color-hover: var(--p-action-primary-hovered);
  --p-button-color-active: var(--p-action-primary-pressed);
  --p-button-color-depressed: var(--p-action-primary-depressed);
  background: linear-gradient(to bottom, #6371c7, #5563c1) !important;
  border-color: #3f4eae !important;
  box-shadow: inset 0 1px 0 0 #6774c8, 0 1px 0 0 rgba(22, 29, 37, 0.05), 0 0 0 0 transparent !important;
  color: white;
}
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background: linear-gradient(to bottom, #6371c7, #5563c1) !important;
}
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: #3f4eae !important;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: linear-gradient(to bottom, #6371c7, #5563c1) !important;
  color: #fff !important;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  height: 100% !important;
}
.ant-select-single .ant-select-selector .ant-select-selection-item, .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: 36px !important;
}
.ant-select-clear,
.ant-select-arrow {
  z-index: 111 !important;
}
.ant-select-selection-item {
  z-index: 11;
}
.ant-select-selection-placeholder {
  opacity: 1;
  z-index: 111;
}
.Polaris-Header-Title__TitleWithMetadataWrapper {
  .Polaris-Badge--statusWarning {
    background: #fead9a;
  }
}
.hidden, [hidden] {
  display: none !important;
}
.Polaris-EmptyState {
  height: 70vh;
}
.results-count {
  padding: 40px 0 10px;
  font-size: 16px;
  font-weight: 500;
}
.Polaris-ResourceList-CheckableButton__Label {
  margin-left: 32px !important;
}
.Polaris-DatePicker--newDesignLanguage .Polaris-DatePicker__Day--selected {
  background: var(--p-interactive) !important;
}
